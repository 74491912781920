<template>
  <div>
    <div class="diy-container-view">
      <div class="sortView">
        <div class="sortNav">
          <a-breadcrumb>
            <a-breadcrumb-item>
              <r-link to="/">
                Home
              </r-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>Custom Gift</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>
      <div class="content-view">
        <div>
          <div class="content-view-left positionSticky userSelectNone">
            <div class="title mb5">Categories</div>
            <a-menu
              :selected-keys="menuSelectKeys"
              style="width: 16vw"
              mode="inline"
              @click="handleProductTypeChange"
            >
              <template v-for="topProductType in productTypes">
                <a-sub-menu
                  v-if="topProductType.children"
                  :key="topProductType.id"
                >
                  <span slot="title" :title="topProductType.name">
                    <span>{{ topProductType.name }}</span>
                  </span>
                  <a-menu-item
                    v-for="subProductType in topProductType.children"
                    :key="subProductType.id"
                  >
                    {{ subProductType.name }}
                  </a-menu-item>
                </a-sub-menu>
                <a-menu-item
                  v-else
                  :key="topProductType.id"
                  :title="topProductType.name"
                >
                  {{ topProductType.name }}
                </a-menu-item>
              </template>
            </a-menu>
          </div>
        </div>
        <div class="content-view-right flex-1">
          <div class="content-view-right-content">
            <div v-for="item in products" :key="item.id" class="content-item">
              <ProductItem :product="item" />
            </div>
          </div>
          <div v-if="productsNum" class="pagination-view">
            <a-pagination
              :page-size.sync="pageSize"
              :page-size-options="[20,32,48,80]"
              class="paginationView"
              show-quick-jumper
              :total="productsNum"
              show-size-changer
              @change="pageChange"
              @showSizeChange="onPageSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { typeTree, blankProductPage } from '@/api/product'
import blankProductTypeCategoryEnum from '@/enums/blank_product/blankProductTypeCategoryEnum.js'
import ProductItem from '../../components/diyproduct_item'
import blankProductTypeEnum from '@/enums/blank_product/blankProductTypeEnum'
export default {
  name: 'DiyList',
  components: {
    ProductItem
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 20,
      currentJumpPage: '',
      pageIndex: 1,
      productTypes: [],
      products: [],
      productsNum: 0,
      selectProductTypeId: null
    }
  },
  computed: {
    menuSelectKeys() {
      if (this.selectProductTypeId) {
        return [this.selectProductTypeId]
      } else {
        return [-1]
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (this.$route.path !== '/custom-gift') {
          return
        }
        var typeId = this.$route.query.type
        if (typeId) {
          if (this.selectProductTypeId && this.selectProductTypeId === typeId) {
            return
          }
          this.selectProductTypeId = parseInt(typeId)
        } else {
          if (!this.selectProductTypeId) {
            return
          }
          this.selectProductTypeId = null
        }
        this.loadProducts()
      }
    }
  },
  mounted() {
    console.log('mounted')
    var typeId = this.$route.query.type
    if (typeId) {
      this.selectProductTypeId = parseInt(typeId)
    } else {
      this.selectProductTypeId = null
    }
    this.loadProductTypes()
  },
  methods: {
    async loadProductTypes() {
      let type = ''
      if (this.$route.query.types === '5') {
        type = blankProductTypeCategoryEnum.SDS.value
      } else {
        type = blankProductTypeCategoryEnum.DIY.value
      }
      const { data } = await typeTree({
        blankProductTypeCategory: type
      })
      this.productTypes = data
      if (this.productTypes && this.productTypes.length) {
        if (this.productTypes[0].children && this.productTypes[0].children.length) {
          this.selectProductTypeId = this.productTypes[0].children[0].id
        } else {
          this.selectProductTypeId = this.productTypes[0].id
        }
        this.loadProducts()
      }
    },
    async loadProducts() {
      var postData = {}
      postData.typeId = this.selectProductTypeId
      postData.current = this.currentPage
      postData.size = this.pageSize
      if (this.$route.query.types === '5') {
        postData.types = [blankProductTypeEnum.SDS.value]
        postData.blankProductTypeCategory =
          blankProductTypeCategoryEnum.SDS.value
      } else {
        postData.types = [blankProductTypeEnum.DIY.value]
        postData.blankProductTypeCategory =
          blankProductTypeCategoryEnum.DIY.value
      }
      var { data } = await blankProductPage(postData)
      this.productsNum = data.total
      this.products = data.records
    },
    reloadProducts() {
      this.currentPage = 1
      this.loadProducts()
    },
    handleProductTypeChange(e) {
      var selectProductTypeId = null
      if (e.key === -1) {
        selectProductTypeId = null
      } else {
        selectProductTypeId = e.key
      }
      if (selectProductTypeId) {
        this.callNewPage(`/custom-gift?type=` + selectProductTypeId)
      } else {
        this.callNewPage(`/custom-gift`)
      }
      // this.reloadProducts()
    },
    handleSizeChange(current, pageSize) {
      // console.log(current, pageSize)
      this.pageSize = pageSize
      this.loadProducts()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadProducts()
    },
    pageChange(page, pageSize) {
      this.currentPage = page
      this.pageSize = pageSize
      this.loadProducts()
    },
    onPageSizeChange(current, size) {
      this.currentPage = 1
      this.pageSize = size
      this.loadProducts()
    }
  }
}
</script>

<style scoped lang="less">
.paginationView /deep/ .ant-select-selection--single {
  height: 39px !important;
  line-height: 39px !important;
  background-color: #fff !important;
  border-radius: 8px;
  border: none !important;
}
.ant-input {
  height: 39px !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  border: none !important;
  &:focus {
    background-color: #fff !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.ant-btn {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #4cbbf9 !important;
  &:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    opacity: 1;
  }
  &:disabled {
    background-color: #f5f8fa !important;
    border-color: #f5f8fa !important;
    color: #999999;
  }
  &:focus {
    background-color: #ffffff !important;
    color: #4cbbf9 !important;
    // box-shadow: inset 0 3px 5px rgb(0,134,210);
  }
  &:active {
    background-color: #ffffff !important;
    border-color: #4cbbf9 !important;
    // box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}
.ant-dropdown-trigger {
  .actionsBtnicon {
    transition: all 0.2s ease-in;
  }
}
.ant-dropdown-open {
  .actionsBtnicon {
    transform: rotate(180deg);
    transition: all 0.2s ease-in;
  }
}
.diy-container-view {
  // background-color: #F3F6F9;
  // height: 100%;
  width: 1400px;
  // padding-top: 40px;
  margin: 0 auto;
  .bannerView {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    .bannerItem {
      width: 100%;
      height: 270px;
      // background-color: aqua;
      border-radius: 15px;
    }
  }
  .sortView {
    width: 100%;
    display: flex;
    align-items: center;
    .sortNav {
      // display: flex;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .dropdownView {
      // width: 160px;
      margin-left: auto;
      border: none !important;

      .dropdownBtn {
        // width: 160px;
        padding: 11px 14px;
        border: none !important;
      }
    }
  }
  .content-view {
    display: flex;
    width: 100%;
    .content-view-left {
      width: 220px;
      background-color: #ffffff;
      top: 80px;
      border-radius: 10px;
      padding: 12px;
      .title {
        // color: rgba(171, 174, 191, 100);
        padding-left: 10px;
        font-size: 16px;
      }
      .ant-menu {
        width: 100% !important;
        border-right: none !important;
        font-size: 14px !important;
        /deep/ .ant-menu-submenu-title {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 0px !important;
          padding-right: 30px !important;
          font-size: 14px !important;
        }
      }
      .ant-menu-root > li {
        padding-left: 10px !important;
      }
      .ant-menu-sub > li {
        padding-left: 24px !important;
      }
      // .ant-menu-item{
      //   min-width: 100% !important;
      //   padding-left: 20px !important;
      //   padding-right: 20px !important;
      //   font-size: 14px !important;
      // }
    }
    .content-view-right {
      padding-left: 24px;
      .content-view-right-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;

        .content-item {
          // width: 289px;
          // height: 474px;
          // background-color: #FFFFFF;
          width: 24%;
          border-radius: 10px;
          // padding: 14px;
          margin-bottom: 20px;
          margin-left: 5px;
          margin-right: 5px;
          .imgView {
            width: 261px;
            // width: 100%;
            height: 284px;
            background: #c4c4c4;
            position: relative;
            border-radius: 8px;
            // img {
            //   border-radius: 8px;
            //   width: 261px;
            //   height: 284px;
            // }
            .view-product-btn {
              cursor: pointer;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 261px;
              height: 44px;
              background-color: #009ef7;
              border-radius: 8px;
              font-size: 14px;
              font-family: Segoe UI-Regular, Segoe UI;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .product-title {
            padding-top: 14px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .bestseller {
            padding-top: 6px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #bbbbbb;
          }
          .price {
            padding-top: 20px;
            font-size: 20px;
            font-family: SegoeUI-Bold-, SegoeUI-Bold;
            font-weight: normal;
            color: #009ef7;
          }
        }
      }
      .pagination-view {
        width: 100%;
        // height: 10%;
        // background-color: #ffffff;
        margin-top: 30px;
        padding-right: 20px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .jumpPageInput {
          width: 60px;
          height: 39px;
          padding-right: 10px;
        }
        .goBtn {
          height: 39px;
          border-radius: 8px;
        }
      }
    }
  }
}
.ant-menu-item::after{
  border: none;
}
.ant-menu-item{
  border-radius: 12px !important;
}
.ant-menu-item-selected{
  color: rgba(0, 158, 247, 100) !important;
  background-color: rgba(242, 246, 255, 100) !important;
  border-radius: 12px !important;
}
</style>
